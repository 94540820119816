module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Reactive Resume","short_name":"RxResume","description":"A free and open-source resume builder.","start_url":"/?source=pwa","icon":"static/images/logo.png","background_color":"#FFFFFF","theme_color":"#444444","display":"standalone","cache_busting_mode":"none","icons":[{"src":"static/icons/maskable.png","sizes":"196x196","type":"image/png","purpose":"any maskable"}],"shortcuts":[{"name":"Dashboard","short_name":"Dashboard","description":"View/manage all your resumes at a glance","url":"/app/dashboard?source=pwa"}],"include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["**/*"]},"precachePages":["","/app/*","/blog/*"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
